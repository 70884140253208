import { createAction, handleActions } from "redux-actions";

export const setLocation = createAction("SET_LOCATION");
export const clearLocation = createAction("CLEAR_LOCATION");

type LocationProps = {
	lat?: string;
	lng?: string;
	street?: string;
};

export const emptyLocation = {
	lat: undefined,
	lng: undefined,
	street: "",
};

const LocationReducer = handleActions(
	{
		SET_LOCATION: (state: LocationProps, action: any) => {
			return { ...state, ...action.payload };
		},
		CLEAR_LOCATION: () => {
			return {
				...emptyLocation,
			};
		},
	},
	{
		...emptyLocation,
	}
);
export default LocationReducer;
