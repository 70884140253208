import { debounce } from 'lodash';
import { useState } from 'react';

export const isMobile = (): boolean => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const useDebounceSearch = (callback?: (query: string) => void, delay: number = 300) => {
    const [ debounceSearch ] = useState(() => debounce(callback ? callback : () => {}, delay));
    return debounceSearch;
};
