import React, { FC, useEffect, HTMLAttributes, isValidElement } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './modal.scss';
import Button from '../Button';
import Message from '../Message';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
	approveBtn?: string,
	cancelBtn?: string | boolean,
	className?: string,
	content?: string | JSX.Element,
	fill?: boolean,
	footer?: JSX.Element,
    header?: string,
	loading?: boolean,
	noButtons?: boolean,
	onApprove?: () => void,
	onCancel?: () => boolean,
	onClose: () => void,
	onTop?: boolean,
	open: boolean,
	preventClose?: boolean,
	size?: 'large',
	type?: 'error' | 'message' | 'default',
};

const Modal: FC<ModalProps> = ({
	approveBtn,
	cancelBtn,
    children,
	className,
	content,
	fill,
    header,
	noButtons,
	onApprove,
	onCancel,
    onClose,
    open,
	onTop,
	preventClose,
	size,
	type,
}: ModalProps) => {
    // const [ isLoading, setIsLoading ] = useState<boolean>(false);

	useEffect(() => {
        const handleKeyup = (e: KeyboardEvent): void => {
            if (e.key === 'Escape') {
                onClose();
            }
        }
		window.addEventListener('keyup', handleKeyup, true);
        return () => window.removeEventListener('keyup', handleKeyup, true);
	}, [onClose]);

	// useEffect(() => {
	// 	if (!open) return;
    //     setIsLoading(true);
    //     setTimeout(() => setIsLoading(false), 300);
	// }, [open]);

	const handleApprove = (): void => {
		if (onApprove) {
			onApprove();
		}
		if (!preventClose) {
			onClose();
		}
	}

	const handleCancel = (): void => {
		let close = true;
		if (onCancel) {
			close = onCancel();
		}
		if (close) {
			onClose();
		}
	}

	return (
		<AnimatePresence exitBeforeEnter>
			{open && (
				<>
					<motion.div
						onClick={onClose}
						className="modal-v2-overlay"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					/>
					<div className={`modal-v2 ${size ? `modal-v2--${size}` : ''} ${onTop ? 'on-top' : ''} ${className || ''}`}>
						<motion.div
							initial={{ opacity: 0, scale: 0.4 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, y: -300 }}
							className="modal-v2-wrapper"
						>
							<div className="modal-v2-header">
								<span>{header || 'Informatie'}</span>
                                <i
                                    className="fal fa-times"
                                    onClick={onClose}
                                />
							</div>
							{/* {isLoading ? (
								<div className="modal-body_loading" style={{ minHeight: 100 }}>
									<i className="fal fa-spin fa-spinner-third"></i>
								</div>
							) : (<> */}
								<div className={`modal-v2-body ${fill ? 'modal-v2-body-fill' : ''}`}>
									{type && type !== 'default' ? (<>
										<Message
											content={content || ''}
											type={type}
										/>
										{children}
									</>) : (
										content
											? (isValidElement(content) ? {content} : <p className="modal-v2-text">{content}</p>)
											: children
									)}
								</div>
								{noButtons ? (
									<div className="modal-v2-footer" />
								) : (<>
									{(cancelBtn || approveBtn || onApprove || onCancel) && (
										<div className="modal-v2-footer">
											{(cancelBtn || onCancel) && (
												<Button
													label={!cancelBtn || cancelBtn === true ? 'Annuleren' : cancelBtn}
													onClick={handleCancel}
												/>
											)}
											{(approveBtn || onApprove) && (
												<Button
													label={approveBtn || 'Ok'}
													onClick={handleApprove}
													color="green"
												/>
											)}
										</div>
									)}
									{!cancelBtn && !approveBtn && !onApprove && !onCancel && (
										<div className="modal-v2-footer" style={{ justifyContent: 'flex-end' }}>
											<Button
												label="Ok"
												onClick={handleApprove}
												color="green"
											/>
										</div>
									)}
								</>)}
							{/* </>)} */}
						</motion.div>
					</div>
				</>
			)}
		</AnimatePresence>
	);
};

export default React.memo(Modal);
