import React, { FC } from 'react';
import Dropdown, { DropdownProps } from './components/Dropdown';
import styles from './styles.module.scss';

interface FormDropdownProps extends DropdownProps {
    info?: string,
    label?: string,
    required?: boolean,
}

const FormInput: FC<FormDropdownProps> = ({ info, label, required, ...props }): JSX.Element => {
    return (
        <div
            className={[
                styles.group,
                props.error && styles.hasError,
            ].join(' ')}
        >
            {label && <label className={styles.label}>
                {label}
                {required && <span>*</span>}
            </label>}
            <Dropdown {...props} />
            <p className={styles.info}>
                {props.error !== true && props.error !== undefined ?props.error : info}
            </p>
        </div>
    )
}

export default FormInput;
