import React, { useState, useEffect } from 'react';
import { toggleFullscreenLoader } from '../FullscreenLoader';
import Modal, { ModalProps } from '../Modal';

export const toast = (detail: Partial<ModalProps>, skipLoader: boolean = false): void => {
    if (!skipLoader) {
        toggleFullscreenLoader(false);
    }
    dispatchEvent(new CustomEvent('kit-toast', { detail }));
}

const NotificationCenter = (): JSX.Element | null => {
    const [ messages, setMessages ] = useState<Partial<ModalProps>[]>([]);

    const handleAddMessage = ({ detail }: any) => {
        if (!detail.content || detail.content === '') return;
        setMessages([
            ...messages,
            detail,
        ]);
    }

    useEffect(() => {
        window.addEventListener('kit-toast', handleAddMessage);
        return () => window.removeEventListener('kit-toast', handleAddMessage);
    });

    const handleClose = (): void => {
        setMessages(messages.filter((o, i) => i !== 0));
    }

    return messages[0] ? (
        <Modal
            open={true}
            onClose={handleClose}
            {...messages[0]}
        />
    ) : null;
}

export default NotificationCenter;
