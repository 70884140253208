import React, { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type ColorType = 'grey' | 'green' | 'white' | 'red';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    block?: boolean,
    color?: ColorType,
    disabled?: boolean,
    href?: string,
    label?: string,
    link?: boolean,
    target?: string,
    type?: string,
    tooltip?: string,
}

const Button: FC<ButtonProps> = ({
    block,
    color,
    disabled,
    children,
    className,
    href,
    label,
    link,
    onClick,
    target,
    type,
    tooltip,
    ...props
}) => {
    const elemProps: any = {
        className: [
            styles.base,
            block && styles.block,
            disabled && styles.disabled,
            link && styles.link,
            color && styles[`${color}`],
            className,
        ].join(' '),
        onClick: disabled ? undefined : onClick,
        ...props
    }

    const innerContent = (<>
        {children || label}
        {tooltip && (
            <div className="tooltip">
                {tooltip}
            </div>
        )}
    </>);

    if (href && !disabled) {
        if (target) {
            elemProps.href = href;
            elemProps.target = target;
            return <a {...elemProps}>{innerContent}</a>;
        } else {
            elemProps.to = href;
            return <Link {...elemProps}>{innerContent}</Link>;
        }
    }

    return <button {...elemProps} type={type || 'button'}>{innerContent}</button>
}

export default Button;
