import React, { FC, InputHTMLAttributes } from 'react';
import Input, { InputProps } from './components/Input';
import styles from './styles.module.scss';

interface FormInputProps extends InputProps {
    info?: string,
    label?: string,
}

const FormInput: FC<FormInputProps & InputHTMLAttributes<HTMLInputElement>> = ({
    info,
    label,
    ...props
}): JSX.Element => {
    return (
        <div
            className={[
                styles.group,
                props.error && styles.hasError,
            ].join(' ')}
        >
            {label && <label className={styles.label}>
                {label}
                {props.required && <span>*</span>}
            </label>}
            <Input {...props} />
            {(typeof props.error === 'string' || info) && (
                <p className={styles.info}>
                    {props.error || info}
                </p>
            )}
        </div>
    )
}

export default FormInput;
