import React from "react";
import "./checkbox.scss";
type CheckBoxProps = {
	value?: boolean;
	onChange: (value: boolean) => void;
	label?: any;
};

const CheckBox = ({ value, onChange, label }: CheckBoxProps) => {
	return (
		<div className="checkbox-wrapper" onClick={() => onChange(!value)}>
			<div
				className={`checkbox ${value ? "checkbox-checked" : ""}`}
			>
				<i className="fas fa-check"></i>
			</div>
			{label && <div className="checkbox-label">{label}</div>}
		</div>
	);
};

export default CheckBox;
