import React, { FC, useEffect, useState } from 'react';
import StyledText from '../StyledText';

interface SuccessProps {
    title?: string,
    content?: string,
    icon?: string,
}

export const showSuccess = (params?: SuccessProps): void => {
    dispatchEvent(new CustomEvent('show-success', { detail: params || {} }));
}

const Success: FC = (): JSX.Element => {
    const [ show, setShow ] = useState<boolean>(false);
    const [ params, setParams ] = useState<SuccessProps>({});

    useEffect(() => {
        const handle = ({ detail }: any): void => {
            setParams(detail || {});
            setShow(true);
            setTimeout(() => setShow(false), 3000);
        }

        window.addEventListener('show-success', handle, true);
        return () => window.removeEventListener('show-success', handle, true);
    });

    return (
        <div className={`timeslot-form-success tfsf${show ? ' tfsf-show' : ''}`}>
            <i className={params.icon || "fal fa-check"}></i>
            <StyledText type="title">
                {params.title || 'Uw reservering is bevestigd!'}
            </StyledText>
            <StyledText>{params.content || 'U ontvangt een bevestiging via de mail'}</StyledText>
        </div>
    );
}

export default Success;
