import React from "react";
import "./switch.scss";
import StyledText from "../StyledText";
type SwitchProps = {
	onChange: (val: boolean) => void;
	value: boolean;
	label: string;
	aan?: string,
	uit?: string,
	full?: boolean,
};

const Switch = ({ full, onChange, value, label, aan, uit }: SwitchProps) => {
	return (
		<div className={`switch-wrapper ${full ? 'sw-full' : ''}`}>
			<StyledText className="switch-label">{label}</StyledText>
			<div onClick={() => onChange(!value)} className="switch">
				<span className={`switch-item ${value ? "switch-active" : ""}`}>
					{aan || 'Aan'}
				</span>
				<span
					className={`switch-item switch-off ${
						!value ? "switch-active" : ""
					}`}
				>
					{uit || 'Uit'}
				</span>
			</div>
		</div>
	);
};

export default Switch;
