import { createAction, handleActions } from 'redux-actions';

export const setChangeNeeded = createAction('SET_CHANGE_NEEDED');
export const setChangeBtnLabel = createAction('SET_CHANGE_BTN_LABEL');

export interface ChangeState {
	needsChange?: string,
	btnLabel: string,
};

const changeReducer = handleActions(
	{
		SET_CHANGE_NEEDED: (state: ChangeState, action: any) => ({
            ...state,
            needsChange: action.payload,
			btnLabel: 'Opslaan',
		}),
		SET_CHANGE_BTN_LABEL: (state: ChangeState, action: any) => ({
            ...state,
            btnLabel: action.payload,
		}),
	}, {
		needsChange: undefined,
		btnLabel: 'Opslaan',
	}
);
export default changeReducer;
