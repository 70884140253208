import qs from 'qs';
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import queryString from "query-string";

const useGet = (route?: string, query?: string, autoUpdate: boolean = true) => {
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState<any>({});
	const [error, setError] = useState(null as any);
	const [mount] = useState<boolean>(autoUpdate);

	const fetchGet = useCallback(async (callback?: (res: any) => void) => {
		setLoading(true);
		try {
			let { data } = (await axios.get(
				`${route}${query ? `?${query}` : ""}`
			)) as any;
			const { page } = qs.parse((query || '')) as any;
			if (page && parseInt(page) > 1) {
				setResult({
					...result,
					data: [...(result.data || []), ...data.data],
					meta: data.meta,
				});
			} else {
				setResult(data);
			}
			setLoading(false);
			if (callback) {
				callback(data);
			}
		} catch (error) {
			setError(error);
			setLoading(false);
		}
	}, [query, route]);// eslint-disable-line

	useEffect(() => {
		if (autoUpdate) {
			fetchGet();
		}
	}, [fetchGet, route, query, mount, autoUpdate]);

	return [result, loading, error, fetchGet, setResult];
};

type Meta = {
	current_page: number;
	from: number;
	last_page: number;
	path: string;
	per_page: number;
	to: number;
	total: number;
};

export const usePagination = (route?: string) => {
	// const [page, setPage] = useState(1);
	const [meta, setMeta] = useState({} as Meta);
	const [error, setError] = useState(null as any);
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState({} as any);

	const fetchGet = useCallback(async (route) => {
		return axios
			.get(`${route}`)
			.then((response: any) => {
				return response;
			})
			.catch((error) => {
				setError(error);
			});
	}, []);

	useEffect(() => {
		setLoading(true);
		fetchGet(route).then((response: any) => {
			setResult(response.data);
			setMeta(response.data.meta);
			setLoading(false);
		});
	}, [fetchGet, route]);

	const next = () => {
		if (!route) return;
		let { query, url } = queryString.parseUrl(route);
		let page = meta.current_page;
		if (!loading && page !== meta.last_page) {
			setLoading(true);
			const q = queryString.stringify({
				...query,
				page: page + 1,
			});
			let r = `${url}?${q}`;
			fetchGet(r).then((response) => {
				const d = response.data;
				setMeta(d?.meta || {});
				setResult(d);
				let data = [...result.data, ...d.data];
				setResult({ ...response.data, data });
				setLoading(false);
			});
		}
	};

	return [result, loading, error, next];
};

export default useGet;
