import React, { ChangeEvent, FC, InputHTMLAttributes, KeyboardEvent } from 'react';
import styles from './styles.module.scss';

export interface TextareaChangeProps {
    name: string,
    value?: string | boolean,
}

export interface TextareaProps {
    autoGrow?: boolean,
    error?: boolean | string,
    onChange?: (data: any, e: ChangeEvent<HTMLTextAreaElement>) => void,
    rows?: number,
    transparent?: boolean,
}

const Input: FC<TextareaProps & InputHTMLAttributes<HTMLTextAreaElement>> = ({
    autoGrow,
    className,
    error,
    onChange,
    transparent,
    value,
    onKeyUp,
    ...props
}): JSX.Element => {
    const areaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
            onChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value,
            }, e);
        }
    }

    const handleKeyUp = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
        if (autoGrow && areaRef.current) {
            areaRef.current.style.height = '5px';
            areaRef.current.style.height = `${areaRef.current.scrollHeight + 5}px`;
        }

        if (onKeyUp) {
            onKeyUp(e);
        }
    }

    return (
        <div
            className={[
                styles.base,
                transparent && styles.transparent,
                autoGrow && styles.autoGrow,
            ].join(' ')}
        >
            <textarea
                ref={areaRef}
                className={[
                    styles.input,
                    error && styles.error,
                    className,
                ].join(' ')}
                onChange={handleChange}
                value={value === undefined ? '' : value}
                onKeyUp={handleKeyUp}
                {...props}
            />
        </div>
    )
}

export default Input;
