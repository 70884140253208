import React, { FC, InputHTMLAttributes } from 'react';
import Textarea, { TextareaProps } from './components/Textarea';
import styles from './styles.module.scss';

interface FormTextareaProps extends TextareaProps {
    info?: string,
    label?: string,
}

const FormTextarea: FC<FormTextareaProps & InputHTMLAttributes<HTMLTextAreaElement>> = ({
    info,
    label,
    ...props
}): JSX.Element => {
    return (
        <div
            className={[
                styles.group,
                props.error && styles.hasError,
            ].join(' ')}
        >
            {label && <label className={styles.label}>
                {label}
                {props.required && <span>*</span>}
            </label>}
            <Textarea {...props} />
            {(typeof props.error === 'string' || info) && (
                <p className={styles.info}>
                    {props.error || info}
                </p>
            )}
        </div>
    )
}

export default FormTextarea;
