import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import styles from './styles.module.scss';

export interface InputChangeProps {
    checked?: boolean,
    files?: FileList,
    name: string,
    value?: string | boolean,
}

export interface InputProps {
    error?: boolean | string,
    icon?: string,
    onChange?: (data: any, e: ChangeEvent<HTMLInputElement>) => void,
    transparent?: boolean,
}

const Input: FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
    className,
    error,
    icon,
    onChange,
    transparent,
    value,
    ...props
}): JSX.Element => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange({
                files: e.currentTarget.files,
                name: e.currentTarget.name,
                value: /checkbox|radio/.test(props.type || '')
                    ? e.currentTarget.checked
                    :  e.currentTarget.value,
            }, e);
        }
    }

    return (
        <div
            className={[
                styles.base,
                transparent && styles.transparent,
            ].join(' ')}
        >
            {icon && (
                <div className={styles.icon}>
                    <i className={icon} />
                </div>
            )}
            <input
                className={[
                    styles.input,
                    error && styles.error,
                    icon && styles.withIcon,
                    className,
                ].join(' ')}
                onChange={handleChange}
                value={value === undefined ? '' : value}
                {...props}
            />
        </div>
    )
}

export default Input;
