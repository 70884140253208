import React, { FC } from 'react';
import './message.scss';

interface MessageProps {
    content: string | JSX.Element,
    type?: 'error' | 'message',
}

const Message: FC<MessageProps> = ({ content, type }): JSX.Element => {
    return (
        <div className={`message message-${type || 'message'}`}>
            <div className="message-icon">
                <i className={`far ${type === 'error' ? 'fa-exclamation-triangle' : 'fa-info-circle'}`}></i>
            </div>
            <div className="message-content">
                <p>{content}</p>
            </div>
        </div>
    );
}

export default Message;
