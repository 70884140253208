import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';

export const toggleFullscreenLoader = (state: boolean): void => {
    dispatchEvent(new CustomEvent('toggle-fullscreen-loader', { detail: { state } }));
}

interface FullscreenLoaderProps {
    absolute?: boolean,
    relative?: boolean,
    state?: boolean,
    text?: string,
}

const FullscreenLoader: FC<FullscreenLoaderProps> = ({ absolute, relative, state, text }): JSX.Element => {
    const [ show, setShow ] = useState<boolean>(state === undefined ? false : state);

    useEffect(() => {
        const handleToggle = ({ detail }: any): void => {
            setShow(detail.state || false);
        }

        window.addEventListener('toggle-fullscreen-loader', handleToggle, true);
        return () => window.removeEventListener('toggle-fullscreen-loader', handleToggle, true);
    }, []);

    useEffect(() => {
        if (state !== undefined) {
            setShow(state);
        }
    }, [state]);

    return (
        <div className={`${styles.container} ${show ? styles.show : ''} ${absolute ? styles.absolute : ''}  ${relative ? styles.relative : ''}`}>
            <div>
                <div className={styles.circle}>
                    <span />
                </div>
                {text || 'verwerken'}
            </div>
        </div>
    );
}

export default FullscreenLoader;
